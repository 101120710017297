import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useUserAgent, withUserAgent} from 'next-useragent';
import {makeStyles} from '@material-ui/core/styles';
import {
  fetchStudyPlanAction,
  setDeviceTokenAction,
  updateProfile,
} from 'core/lib/adapters/redux/actions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DashboardHoc from 'components/DashboardHoc';
import EduBenchmarkHoc from 'components/EduBenchmarkHoc';
import WithAuthHoc from 'components/WithAuthHoc';
import DashboardD from 'components/Dashbord/DesktopD';
import DashboardM from 'components/Dashbord/MobileD';
import {messaging} from '../../init-fcm';
import {Box, Modal} from '@material-ui/core';
import AIAnalysisRobo from '../../components/AIAnalysisRobo';
const useStyles = makeStyles((theme) => ({
  mainPagePedding: {
    padding: '16px',
    '@media (max-width: 991px)': {
      padding: '8px',
    },
  },
  infoModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px 100px',
  },
  infoModalMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 1rem',
  },
  iconContainer: {
    width: 78,
    height: 78,
    borderRadius: '50%',
    overflow: 'hidden',
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '50px',
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginTop: 28,
    lineHeight: '29px',
  },
  modalAccessBtn: {
    width: 102,
    height: 34,
    background: theme.palette.primary.main,
    border: 'none',
    outline: 'none',
    borderRadius: '3px',
    marginLeft: '10px',
    marginTop: 22,
    color: '#fff',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  modalCloseBtn: {
    width: 102,
    height: 34,
    background: '#DADADA',
    border: 'none',
    outline: 'none',
    borderRadius: '3px',
    marginTop: 22,
    '&:hover': {
      background: '#DADADA',
    },
  },
}));

const Dashboard = ({useragent}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [firebaseModal, setFirebaseModal] = useState(false);
  const [checkToday, setCheckToday] = useState(false);

  const [open, setOpen] = useState(true);
  const isLogin = localStorage?.getItem('isLogin');

  const getFirebasePermission = () => {
    setFirebaseModal(false);
    if (messaging) {
      messaging
        .requestPermission()
        .then(async () => {
          const token = await messaging.getToken();
          console.log('TOKEN', token);
          localStorage.setItem('firebase', 'access');
          const data = {
            device_token: token,
          };
          dispatch(setDeviceTokenAction(data));
        })
        .catch(() => {
          localStorage.setItem('firebase', 'denied');
        });
    }
    navigator.serviceWorker.addEventListener('message', (message) =>
      alert(message),
    );
  };

  useEffect(() => {
    dispatch(fetchStudyPlanAction());
    const firebasePer = localStorage.getItem('firebase');
    if (!firebasePer) setFirebaseModal(true);
    if (auth && auth.user && !auth.user.timezone) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      dispatch(
        updateProfile({
          _method: 'PUT',
          timezone: userTimezone,
        }),
      );
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   console.log("under useEffect  😘😘")
  //   const lastShownDate = localStorage.getItem('modalLastShownDate');
  //   console.log("lastShownDate 😘😘", lastShownDate)
  //   const today = new Date().toLocaleDateString();
  //   console.log("today😘😘", today)

  //   if (lastShownDate !== today) {
  //     console.log("if condition 😘😘😘")
  //     setCheckToday(true);
  //     localStorage.setItem('modalLastShownDate', today);
  //   }
  // }, []);
  return (
    <EduBenchmarkHoc title="PTE Dashboard - Benchmark">
      <WithAuthHoc>
        <DashboardHoc t={t}>
          <Box className={classes.mainPagePedding}>
            <Grid container direction="column">
              {/* {useragent.isMobile || useragent.isTablet ? (
              <DashboardM useragent={useragent} />
            ) : (
              <DashboardD useragent={useragent} />
            )} */}
              <DashboardD useragent={useragent} />
            </Grid>
          </Box>

          <Dialog open={firebaseModal} onClose={() => setFirebaseModal(false)}>
            <Paper>
              <div
                className={
                  useragent.isMobile || useragent.isTablet
                    ? classes.infoModalMobile
                    : classes.infoModal
                }>
                <div className={classes.iconContainer}>!</div>
                <div className={classes.description}>
                  Do you want receive notifications?
                </div>
                <Grid container justify="center">
                  <Button
                    className={classes.modalCloseBtn}
                    onClick={() => {
                      setFirebaseModal(false);
                      localStorage.setItem('firebase', 'denied');
                    }}>
                    No
                  </Button>
                  <Button
                    className={classes.modalAccessBtn}
                    onClick={getFirebasePermission}>
                    Yes
                  </Button>
                </Grid>
              </div>
            </Paper>
          </Dialog>
          {/* {firebaseModal == false && isLogin == "false" && <Modal */}
          {/* {!firebaseModal && isLogin == "false" && checkToday && < Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <AIAnalysisRobo setaiAnalysis={setOpen} />
          </Modal>} */}
        </DashboardHoc>
      </WithAuthHoc>
    </EduBenchmarkHoc>
  );
};
export const getServerSideProps = (context) => {
  const useragent = useUserAgent(context.req.headers['user-agent']);
  return {
    props: {useragent},
  };
};

Dashboard.propTypes = {
  useragent: PropTypes.object,
};

Dashboard.defaultProps = {
  useragent: {},
};

export default withUserAgent(Dashboard);
