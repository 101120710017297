import {
  GET_ALL_TICKET,
  GET_OPEN_TICKET,
  GET_CLOSE_TICKET,
  GET_TICKET_CATEGORIES,
  GET_TICKET_PRIORITIES,
  CREATE_TICKET,
  GET_TICKET,
  GET_TICKET_MESSAGE,
  SEND_TICKET_MESSAGE,
  CLOSE_TICKET,
  OPEN_TICKET,
} from './actions'

export const getAllTicketAction = (payload) => ({
  type: GET_ALL_TICKET,
  payload,
})

export const getOpenTicketAction = (payload) => ({
  type: GET_OPEN_TICKET,
  payload,
})

export const getCloseTicketAction = (payload) => ({
  type: GET_CLOSE_TICKET,
  payload,
})

export const getTicketCategoriesAction = () => ({
  type: GET_TICKET_CATEGORIES,
})

export const getTicketPrioritiesAction = () => ({
  type: GET_TICKET_PRIORITIES,
})

export const createTicketAction = ({data}) => ({
  type: CREATE_TICKET,
  payload: data,
})

export const getTicketAction = ({id}) => ({
  type: GET_TICKET,
  payload: id,
})

export const getTicketMessageAction = (payload) => {
  return {
    type: GET_TICKET_MESSAGE,
    payload,
  }
}

export const sendTicketMessageAction = ({id, data}) => ({
  type: SEND_TICKET_MESSAGE,
  payload: {
    id,
    data,
  },
})

export const closeTicketAction = ({id}) => ({
  type: CLOSE_TICKET,
  payload: id,
})

export const openTicketAction = ({id}) => ({
  type: OPEN_TICKET,
  payload: id,
})
