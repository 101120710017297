import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CrossIcon from 'components/Icons/gen/Cross';
import CheckIcon from 'components/Icons/gen/Check';
import Player from 'components/Player';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(2),
  },
  writingButtons: {
    color: 'white',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  textFieldContainer: {
    marginBottom: theme.spacing(2),
    lineHeight: '32.86px',
    display: 'flow-root',
  },
  totalWordCount: {
    marginRight: theme.spacing(0.5),
  },
  timeFinished: {
    color: 'red',
  },
  playerContainer: {
    marginBottom: theme.spacing(2),
  },
  itemContainer: {
    display: 'inline-flex',
    marginBottom: 10,
    fontSize: '18px',
    fontWeight: 400,
    color: '#353535',
  },
  itemChecked: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const HighlightIncorrectWords = ({
  t,
  question,
  onAnswered,
  submittedQuestion,
  answer,
  questionAnswer,
  mockAnswer,
  useragent,
  pausePlay,
  mock,
  playerStartAfter,
  showStartTimer,
  setIsStopNextQuestion,
  resultComponent,
  onPlayerStarted
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [textareaInputArray, setTextareaInputArray] = useState([]);
  const [startTimer, setStartTimer] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(true);
  const [selectedWords, setSelectedWords] = useState([]);

  const baseClass = {
    display: 'inline-block',
    margin: '0px 2px',
    cursor: 'pointer',
  };

  const selectedClass = {
    color: 'rgb(255, 255, 255)',
    padding: '2px 10px',
    borderRadius: '4px',
    backgroundColor: 'rgb(231 200 3)',
  };

  const correctClass = {
    color: 'rgb(255, 255, 255)',
    padding: '2px 10px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
  };

  const correctNotSelectClass = {
    color: 'black',
    padding: '2px 10px',
    borderRadius: '4px',
    backgroundColor: '#dadada',
  };

  const checkIsSelected = (word, index) => {
    return (
      selectedWords &&
      selectedWords.length > 0 &&
      selectedWords.findIndex((i) => i.value === word && i.key === index) !== -1
    );
  };

  const checkIsCorrect = (word, index) => {
    return !!(
      submittedQuestion &&
      questionAnswer['acceptable-answers'] &&
      questionAnswer['acceptable-answers'].data &&
      questionAnswer['acceptable-answers'].data[0].answer.findIndex(
        (i) => i.value === word && i.key === index,
      ) !== -1
    );
  };

  const selectWordHandler = (word, index) => {
    if (submittedQuestion) {
      return;
    }

    const existWord = checkIsSelected(word, index);
    if (!existWord) {
      setSelectedWords((prev) => [...prev, {key: index, value: word}]);
    } else {
      setSelectedWords((prev) =>
        prev.filter((i) => i.value !== word || i.key !== index),
      );
    }
  };

  useEffect(() => {
    if (question && question.question_data && question.question_data.text) {
      setTextareaInputArray(question.question_data.text.trim().split(/\s+/));
    }
  }, [question]);

  useEffect(() => {
    onAnswered(selectedWords);
  }, [selectedWords]);

  useEffect(() => {
    if (mockAnswer) setSelectedWords(answer);
  }, [answer]);

  return (
    <Grid container justify={!mockAnswer ? 'center' : 'flex-start'}>
      <Grid
        item
        lg={5}
        md={6}
        sm={12}
        xs={12}
        className={classes.playerContainer}>
        <Player
          currentStatus={true}
          setIsStopNextQuestion={setIsStopNextQuestion}
          isAnswer={false}
          t={t}
          src={
            question.question_media
              ? Object.values(question.question_media.audio)[
                  Object.values(question.question_media.audio).length - 1
                ]
              : null
          }
          autoplayAfter={playerStartAfter}
          onPlayStart={() => {
            setWaitingForPlayer(false);
          }}
          onPlayFinish={() => {
            setStartTimer(true);
          }}
          pausePlay={pausePlay}
          useragent={useragent}
          mock={mock}
          showStartTimer={showStartTimer}
          justifyContentPlayer={resultComponent ? 'start' : null}
          onPlayStarted ={onPlayerStarted}
        />
      </Grid>
      <Grid item xs={12} className={classes.textFieldContainer}>
        {textareaInputArray.map((wrd, index) => {
          let style = {...baseClass};
          if (checkIsSelected(wrd, index)) {
            style = {...baseClass, ...selectedClass};
          }
          if (checkIsSelected(wrd, index) && checkIsCorrect(wrd, index)) {
            style = {...baseClass, ...correctClass};
          }
          if (!checkIsSelected(wrd, index) && checkIsCorrect(wrd, index)) {
            style = {...baseClass, ...correctNotSelectClass};
          }
          return (
            <div className={classes.itemContainer}>
              <p
                key={wrd + index}
                style={style}
                onClick={() => selectWordHandler(wrd, index)}>
                {wrd}
              </p>
              <span className={classes.itemChecked}>
                {checkIsSelected(wrd, index) && submittedQuestion ? (
                  <>
                    {checkIsCorrect(wrd, index) ? (
                      <CheckIcon
                        color={theme.palette.primary.main}
                        className={classes.icon}
                      />
                    ) : (
                      <CrossIcon color="#f00" className={classes.icon} />
                    )}
                  </>
                ) : null}
              </span>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

HighlightIncorrectWords.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_type: PropTypes.string.isRequired,
    question_media: PropTypes.any,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.array,
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
  useragent: PropTypes.object,
  pausePlay: PropTypes.bool,
  mockAnswer: PropTypes.bool,
  mock: PropTypes.bool,
};
HighlightIncorrectWords.defaultProps = {
  answer: null,
  useragent: {},
  pausePlay: false,
  mockAnswer: false,
  mock: false,
};

export default HighlightIncorrectWords;
