import {
  ANSWER_MOCK_ACTION,
  FILTER_MOCK_ANSWERS_ACTION,
  GET_MOCK_ANSWER,
  GET_MOCK_USER_LIST,
  GET_QUESTIONS_MOCK,
  INDEX_MOCK,
  GET_MOCK,
  SHOW_MOCK_USER,
  START_MOCK,
  MOCK_TEST_SCORING,
  SET_CURRENT_MOCK_TEST,
  MANUAL_MOCK_FINISH,
  SKIP_MOCK_QUESTION,
  GET_MOCK_SKILL_QUESTIONS,
  MOCK_TEST_GRADING,
  MOCK_TEST_SECTION,
  MOCK_TEST_QUESTION,
  DELETE_MOCK_USER,
  RECORD_iNTRO,
  RECORD_STOP_SAVE,
  GET_QUESTIONS_ANSWER_MOCK,
  GET_RESULT_MOCK,
  START_PLAYER_MOCK_RESULT,
  STOP_PLAYER_MOCK_RESULT,
} from './actions'

export const setCurrentMockTest = (payload) => ({
  type: SET_CURRENT_MOCK_TEST,
  payload,
})

export const indexMockAction = (payload) => ({
  type: INDEX_MOCK,
  payload,
})

export const getMockAction = (payload) => ({
  type: GET_MOCK,
  payload,
})

export const startMockAction = (payload) => ({
  type: START_MOCK,
  payload,
})

export const showMockUserAction = ({mock_id}) => ({
  type: SHOW_MOCK_USER,
  payload: {mock_id},
})

export const getQuestionsMockAction = (payload) => ({
  type: GET_QUESTIONS_MOCK,
  payload,
})

export const answerMockAction = ({
  question_id,
  answer,
  questionType,
  mock_id,
  time_taken,
}) => ({
  type: ANSWER_MOCK_ACTION,
  payload: {question_id, answer, questionType, mock_id, time_taken},
})

export const filterMockAnswersAction = (payload) => ({
  type: FILTER_MOCK_ANSWERS_ACTION,
  payload,
})

export const getMockUserListAction = (payload) => ({
  type: GET_MOCK_USER_LIST,
  payload,
})
export const getMockAnswersAction = ({mock_id, question_id}) => ({
  type: GET_MOCK_ANSWER,
  payload: {
    mock_id,
    question_id,
  },
})

export const mockTestScoringAction = ({id}) => ({
  type: MOCK_TEST_SCORING,
  payload: {id},
})

export const manualMockFinishAction = (payload) => ({
  type: MANUAL_MOCK_FINISH,
  payload,
})

export const skipMockQuestion = (payload) => ({
  type: SKIP_MOCK_QUESTION,
  payload,
})

export const getMockSkillQuestionsAction = (payload) => ({
  type: GET_MOCK_SKILL_QUESTIONS,
  payload,
})

export const getMockTestGradingAction = (payload) => ({
  type: MOCK_TEST_GRADING,
  payload,
})

export const getMockTestSectionAction = (payload) => ({
  type: MOCK_TEST_SECTION,
  payload,
})

export const getMockTestQuestionAction = (payload) => ({
  type: MOCK_TEST_QUESTION,
  payload,
})

export const deleteMockUserAction = (payload) => ({
  type: DELETE_MOCK_USER,
  payload,
})

export const recordIntro = (payload) => ({
  type: RECORD_iNTRO,
  payload,
})

export const recordStopSave = (payload) => ({
  type: RECORD_STOP_SAVE,
  payload,
})

export const getQuestionsAnswerMockAction = (payload) => ({
  type: GET_QUESTIONS_ANSWER_MOCK,
  payload,
})

export const getGetResultMockAction = (payload) => ({
  type: GET_RESULT_MOCK,
  payload,
})

export const startPlayerMockResult = (payload) =>{
  console.log("payload is startPlayerMock 🙌🙌", payload)
  return{
  type : START_PLAYER_MOCK_RESULT , 
  payload
}}

export const stopPlayerMockResult = ()=>({
  type : STOP_PLAYER_MOCK_RESULT
})
