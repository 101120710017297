import {
  SAVE_AND_EXIT_ALERT_MODEL,
  CONTINUE_AND_RESTART_MODEL,
  CONFIRM_MODEL,

  CANNOT_SKIP_MODEL,
  FINISH_EXAM_MODEL,
  BRACK_MODEL,
  RECORDING_STOPPED_MODAL,
  UPGRADE_PACKAGE_MODAL,
  SUBMISSION_SUCCESS_MODAL,
  SUBMISSION_CHECK_RESULT_MODAL,
  TEST_RESUME_MODAL,
  MAIN_TIMES_UP_MODAL,
  TEST_TYPE,
  IS_LOADER,
  RESTART_EXAM_WARNING_MODAL,
} from './actions'

export const isLoaderAction = (payload) => ({
  type: IS_LOADER,
  payload
})

export const testTypeAction = (payload) => (
  console.log("payload", payload),
  {
    type: TEST_TYPE,
    payload
  })

export const saveAndExitAlertModelAction = (payload) => ({
  type: SAVE_AND_EXIT_ALERT_MODEL,
  payload
})

export const continueAndRestartModelAction = (payload) => ({
  type: CONTINUE_AND_RESTART_MODEL,
  payload
})

export const confirmModelAction = (payload) => ({
  type: CONFIRM_MODEL,
  payload
})

export const cannotSkipModelAction = (payload) => ({
  type: CANNOT_SKIP_MODEL,
  payload
})

export const submissinSuccessAction = (payload) => ({
  type: SUBMISSION_SUCCESS_MODAL,
  payload
})

export const finishExamModelAction = (payload) => ({
  type: FINISH_EXAM_MODEL,
  payload
})

export const brackModelAction = (payload) => ({
  type: BRACK_MODEL,
  payload
})

export const recordingStoppedAction = (payload) => ({
  type: RECORDING_STOPPED_MODAL,
  payload
})

export const upgradePackageAction = (payload) => ({
  type: UPGRADE_PACKAGE_MODAL,
  payload
})

export const submissionCheckResultModalAction = (payload) => ({
  type: SUBMISSION_CHECK_RESULT_MODAL,
  payload
})

export const testResumedModalAction = (payload) => ({
  type: TEST_RESUME_MODAL,
  payload
})


export const mainTimesUpModalAction = (payload) => ({
  type: MAIN_TIMES_UP_MODAL,
  payload
})

export const restartModalWarningAction = (payload)=>{
  return {
    type : RESTART_EXAM_WARNING_MODAL,
    payload,
  }
}

