import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'auto',
        minHeight: '380px',
        width: '100%',
        minWidth: '95vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '24px',
        [theme.breakpoints.down('md')]: {
            padding: '24px 12px',
            minWidth: '35vh',
        }
    },
    loading: {
        marginTop: theme.spacing(2),
    },
    loadingReason: {
        fontSize: '20px',
        textAlign: 'center'
    }
}))
const LoadingAIIndicator = ({ isScore }) => {
    const classes = useStyles()
    return (
        <Box className={classes.root}  >
            <CircularProgress size={48} />
            <Typography className={classes.loading} variant="subtitle1">
                Just a sec...
            </Typography>
            <Typography className={classes.loadingReason} variant="subtitle1">
                {`We are generating your ${isScore ? 'score' : 'result'}…`}
            </Typography>
        </Box>
    )
}

export default LoadingAIIndicator
