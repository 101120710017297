import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import Player from '../../../Player';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(2),
  },
  writingButtons: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      padding: '8px 12px',
      '&.MuiButton-contained': {
        padding: '8px 12px',
        height: '40px',
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    }
  },
  textFieldContainer: {
    marginBottom: theme.spacing(2),
  },
  totalCounterAndBtnsAndTimerCounter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      // flexDirection: 'column',
    }
  },
  actionBtnAlign: {
    justifyContent: 'center',
    order: 2,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
      flexWrap: 'nowrap',
      order: 3,
      marginTop: "12px",
      gap: '6px'
    }
  },
  orderCount: {
    order: 1,
    [theme.breakpoints.down('xs')]: {
      order: 1
    }
  },
  orderTimeShow: {
    order: 3,
    [theme.breakpoints.down('xs')]: {
      order: 2
    }
  },
  totalWordCount: {
    marginRight: theme.spacing(0.5),
    fontWeight: 600,
  },
  timer: {
    width: '45px',
    fontWeight: 600,
  },
  timeFinished: {
    color: 'red',
  },
  playerContainer: {
    marginBottom: theme.spacing(2),
  },
  correctSpell: {
    color: 'green',
  },
  wrongSpell: {
    color: 'red',
  },
}));

const SummarizeSpokenText = ({
  t,
  question,
  onAnswered,
  submittedQuestion,
  showCounter,
  useragent,
  mockAnswer,
  mockQuestion,
  stopTimer,
  pausePlay,
  playerStartAfter,
  showStartTimer,
  setIsStopNextQuestion,
  stopAutoPlay,
  resultComponent,
  onPlayerStarted
}) => {
  const classes = useStyles();
  const textFieldRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [spellCheckOn, setSpellCheckOn] = useState(false);
  const [textSelection, setTextSelection] = useState({
    selectStart: null,
    selectEnd: null,
  });
  const [timer, setTimer] = useState(
    question?.question_type === 'WriteEssay' ? 1200 : 600,
  );
  const [additionalTime, setAdditionalTime] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(true);
  const [spellTooltip, setSpellTooltip] = useState(false);
  const [spellChecker, setSpellChecker] = useState(null);
  const maxAllowedWordCount = 300;

  // counter, wordCount,Buttons, onAnswer
  // useEffect(() => {
  //   setWordCount(
  //     text === '' ? 0 : text.trim().replace(/\n/g, ' ').split(' ').length,
  //   );
  //   const shouldRun = debounce(() => {
  //     onAnswered(text);
  //   }, 50);
  //   shouldRun();
  // }, [text]);
  useEffect(() => {
    const calculateWordCount = () => {
      const wordsArray = text.trim().split(/\s+/);
      const wordCount = wordsArray.filter(word => word !== '').length;
      return wordCount;
    };

    const newWordCount = calculateWordCount();
    setWordCount(newWordCount > 0 ? newWordCount : 0);

    const shouldRun = debounce(() => {
      if (newWordCount <= maxAllowedWordCount) {
        onAnswered(text);
      } else {
        onAnswered('');
      }
    }, 50);
    shouldRun();
  }, [text]);
  useEffect(() => {
    if (!stopTimer) {
      if (timer === 0) setAdditionalTime(true);
      const timerTimeout = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => {
        clearTimeout(timerTimeout);
      };
    }
  }, [timer]);

  const renderTime = () => {
    if (timer <= 0) {
      return "00:00";
    }
    const timerAbs = Math.abs(timer);
    const minute = Math.floor(timerAbs / 60);
    const second = timerAbs - 60 * Math.floor(timerAbs / 60);
    return `${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`
      }`;
  };

  const onTextChange = (e) => {
    setText(e.target.value);
    setTextSelection({
      selectStart: document.activeElement.selectionStart,
      selectEnd: document.activeElement.selectionEnd,
    });
  };

  const storeSelected = () => {
    setTextSelection({
      selectStart: document.activeElement.selectionStart,
      selectEnd: document.activeElement.selectionEnd,
    });
  };

  const copySelected = (action) => {
    let el = document.createElement('textarea');
    el.value = text.slice(
      textSelection.selectStart,
      textSelection.selectEnd + 1,
    );
    el.select();
    document.execCommand('copy');
    el = null;
    // if (action !== 'cut')
    //   enqueueSnackbar(t('notif:selectedTextHasBeenCopied'), {
    //     variant: 'success',
    //   });
  };

  const pasteSelected = () => {
    // document.execCommand('paste')
    navigator.clipboard
      .readText()
      .then((s) => {
        setText(
          text.slice(0, textSelection.selectStart) +
          s +
          text.slice(textSelection.selectEnd, text.length),
        );
        // enqueueSnackbar(t('notif:selectedTextHasBeenPasted'), {
        //   variant: 'success',
        // });
      })
      .catch(() => { });
  };

  const cutSelected = () => {
    copySelected('cut');
    setText(
      text.slice(0, textSelection.selectStart) +
      text.slice(textSelection.selectEnd, text.length),
    );
    // enqueueSnackbar(t('notif:selectedTextHasBeenCut'), { variant: 'success' });
  };

  useEffect(() => {
    if (spellChecker) setSpellTooltip(true);
  }, [spellChecker]);

  return (
    <>
      {!mockAnswer ? (
        <Grid container justify="center">
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.playerContainer}>
            <Player
              currentStatus={true}
              setIsStopNextQuestion={setIsStopNextQuestion}
              isAnswer={false}
              t={t}
              src={
                question.question_media
                  ? Object.values(question.question_media.audio)[
                  Object.values(question.question_media.audio).length - 1
                  ]
                  : null
              }
              autoplayAfter={playerStartAfter}
              onPlayStart={() => {
                setWaitingForPlayer(false);
              }}
              onPlayFinish={() => {
                setStartTimer(true);
              }}
              pausePlay={pausePlay}
              useragent={useragent}
              mock={mockQuestion}
              showStartTimer={showStartTimer}
              onPlayStarted={onPlayerStarted}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldContainer}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              disabled={submittedQuestion}
              size="medium"
              // spellCheck={spellCheckOn}
              inputProps={{
                //   autoCorrect: spellCheckOn ? 'on' : 'off',
                spellCheck: spellCheckOn ? 'true' : 'false',
                'data-gramm': spellCheckOn ? 'true' : 'false',
              }}
              autoFocus
              placeholder={t('typeHere')}
              rows={10}
              value={text}
              onChange={onTextChange}
              inputRef={textFieldRef}
              onClick={storeSelected}
            />
          </Grid>
          {useragent.isMobile || useragent.isTablet ? (
            <Grid item xs={12} container justify="space-between">
              <Grid item>
                {showCounter ? (
                  <>
                    <Typography
                      className={classes.totalWordCount}
                      display="inline"
                      variant="subtitle2"
                      style={{ fontSize: '13px' }}>
                      {t('totalWordCount')}
                    </Typography>
                    <Typography
                      display="inline"
                      style={{ color: 'red', fontWeight: 600 }}>
                      {wordCount}
                    </Typography>
                  </>
                ) : null}
              </Grid>
              <Grid item>
                {additionalTime ? (
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    className={classes.totalWordCount}
                    style={{ fontSize: '13px' }}>
                    Additional‬‬ ‫‪time:{' '}
                  </Typography>
                ) : null}
                <Typography
                  display="inline"
                  className={clsx(classes.timer, {
                    [classes.timeFinished]: additionalTime,
                  })}>
                  {showCounter && renderTime()}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              container
              className={classes.totalCounterAndBtnsAndTimerCounter}>
              <Grid item xs={6} sm={3} className={classes.orderCount} >
              {/*   {showCounter ? ( */}
                  <>
                    <Typography
                      className={classes.totalWordCount}
                      display="inline"
                      variant="subtitle2">
                      {t('totalWordCount')}
                    </Typography>
                    <Typography
                      display="inline"
                      style={{ color: 'red', fontWeight: 600 }}>
                    {wordCount}
                    </Typography>
                  </>
                {/* ) : null} */}
              </Grid>
              <Grid item container className={classes.actionBtnAlign} xs={12} sm={6}>
                <Button
                  variant="contained"
                  className={classes.writingButtons}
                  onClick={cutSelected}>
                  {t('cut')}
                </Button>
                <Button
                  variant="contained"
                  className={classes.writingButtons}
                  onClick={copySelected}>
                  {t('copy')}
                </Button>
                <Button
                  variant="contained"
                  className={classes.writingButtons}
                  onClick={pasteSelected}>
                  {t('paste')}
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}
                className={classes.orderTimeShow}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* {additionalTime ? (
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    className={classes.totalWordCount}
                    style={{ fontSize: '13px' }}>
                    Additional‬‬ ‫‪time:{' '}
                  </Typography>
                ) : null} */}
                <Typography
                  display="inline"
                  className={clsx(classes.timer, {
                    [classes.timeFinished]: additionalTime,
                  })}>
                  {showCounter && renderTime()}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container>
          <Grid
            item
            lg={5}
            md={6}
            sm={12}
            xs={12}
            className={classes.playerContainer}>
            <Player
              isAnswer={false}
              t={t}
              src={question?.question_media ? Object.values(question?.question_media.audio)[0] : null}
              autoplayAfter={stopAutoPlay ? false : 3}
              onPlayStart={() => { setWaitingForPlayer(false); }}
              onPlayFinish={() => { setStartTimer(true); }}
              pausePlay={pausePlay}
              showStartTimer={showStartTimer}
              justifyContentPlayer={resultComponent ? 'start' : null}
              onPlayStarted={onPlayerStarted}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

SummarizeSpokenText.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_type: PropTypes.string.isRequired,
    question_media: PropTypes.shape(),
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.string,
  submittedQuestion: PropTypes.bool.isRequired,
  showCounter: PropTypes.bool,
  useragent: PropTypes.object,
  mockAnswer: PropTypes.bool,
  stopTimer: PropTypes.bool,
  pausePlay: PropTypes.bool,
};
SummarizeSpokenText.defaultProps = {
  answer: null,
  showCounter: true,
  useragent: {},
  mockAnswer: false,
  stopTimer: false,
  pausePlay: false,
};

export default SummarizeSpokenText;
