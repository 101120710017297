import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Recorder from './Recorder';
import Player from './Player';

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    marginBottom: theme.spacing(2),
  },
  practiceContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  practiceContainerThree : {
    marginRight : 'auto',
  },
  practiceContainerTwo :{
    marginLeft : 0,
    marginRight : 'auto'
  }
}));
const ListenAndRepeatPractice = ({
  t,
  question,
  onAnswered,
  answer,
  startIn,
  endIn,
  mock,
  mockAnswer,
  useragent,
  onRecordStart,
  onPausePlay,
  onPlayerStarted,
  playerStartAfter,
  showStartTimer,
  isRecordingStop,
  setIsStopNextQuestion,
  isStopNextQuestion,
  setisPlayerStatusRecord,
  isPlayerStatusRecord ,
  resultComponent,
  isMockAnswerModal
}) => {
  console.log("mock answer >>🙌🙌🙌" , mockAnswer , question)
  const classes = useStyles();
  const [startTimer, setStartTimer] = useState(false);
  const [pausePlay, setPausePlay] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(true);

  return (
    <Grid container item lg={6} md={6} sm={12} xs={12} className={resultComponent ? classes.practiceContainerTwo : isMockAnswerModal ?  classes.practiceContainerThree : classes.practiceContainer}>
      <Grid item xs={12} className={classes.playerContainer}>
        <Player currentStatus={true} isAnswer={false} t={t}
          src={question.question_media ? Object.values(question.question_media.audio)[Object.values(question.question_media.audio).length - 1] : null}
          autoplayAfter={playerStartAfter}
          onPlayStart={() => { setWaitingForPlayer(false) }}
          onPlayFinish={() => { setStartTimer(true) }}
          pausePlay={pausePlay || onPausePlay}
          onPlayStarted={onPlayerStarted}
          useragent={useragent}
          mock={mock}
          showStartTimer={showStartTimer}
          setisPlayerStatusRecord={setisPlayerStatusRecord}
          justifyContentPlayer={resultComponent ? 'start' : null}
        />
      </Grid>

      <Grid item xs={12}>
        {!mockAnswer ? (
          <Recorder
            question={question}
            isRecordingStop={isRecordingStop}
            setIsStopNextQuestion={setIsStopNextQuestion}
            isStopNextQuestion={isStopNextQuestion}
            startIn={startIn}
            endIn={endIn}
            onSaveFinish={(file) => onAnswered(file)}
            answer={answer}
            t={t}
            startTimer={startTimer}
            waitingForPlayer={waitingForPlayer}
            onRecordStart={() => { setPausePlay(true); onRecordStart(); }}
            useragent={useragent}
            mock={mock}
            setisPlayerStatusRecord={setisPlayerStatusRecord}
            isPlayerStatusRecord={isPlayerStatusRecord}
            playBeepSound={['RetellLecture', 'AnswerShortQuestion'].some((qt) => qt === question.question_type,)}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

ListenAndRepeatPractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.array.isRequired,
    question_media: PropTypes.shape().isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.string,
  startIn: PropTypes.number.isRequired,
  endIn: PropTypes.number.isRequired,
  mockAnswer: PropTypes.bool,
  useragent: PropTypes.object,
  mock: PropTypes.bool,
  onRecordStart: PropTypes.func,
};
ListenAndRepeatPractice.defaultProps = {
  answer: null,
  mockAnswer: false,
  useragent: {},
  mock: false,
  onRecordStart: () => { },
};

export default ListenAndRepeatPractice;
