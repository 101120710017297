import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from './Icons/gen/Check';
import CrossIcon from './Icons/gen/Cross';
import RadioCheckedIcon from './Icons/gen/RadioChecked';
import RadioUnCheckedIcon from './Icons/gen/RadioUnChecked';
import Player from './Player';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(1),
    '& p': {
      lineHeight: 1.5,
      fontSize: 18,
      fontWeight: 400,
      color: '#353535',
    },
  },
  choiceContainer: {
    marginTop: theme.spacing(2),
  },
  questionText: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    fontSize: '18px',
    color: '#353535',
  },
  icon: {
    // marginLeft: theme.spacing(2),
  },
  rightAnswer: {
    background: '#EDF0F2',
    borderRadius: 4,
    paddingRight: theme.spacing(1),
    marginRight: '8px',
  },
  wrongAnswer: {
    background: '#FAC3C3',
    borderRadius: 4,
    paddingRight: theme.spacing(1),
    marginRight: '8px',
  },
  playerContainer: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      '& span': {
        fontSize: '18px',
        fontWeight: 400,
      },
    },
  },
  disabledLabel: {
    color: 'inherit !important',
  },
  disabledOption: {
    color: `${theme.palette.primary.main} !important`,
  },
}));
const ReadingChoicePractice = ({
  t,
  question,
  onAnswered,
  answer,
  isSingle,
  questionAnswer,
  submittedQuestion,
  isListening,
  mockAnswer,
  useragent,
  pausePlay,
  mock,
  isHighlight,
  playerStartAfter,
  showStartTimer,
  setIsStopNextQuestion,
  resultComponent,
  onPlayerStarted
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [practiceAnswer, setPracticeAnswer] = useState(
    answer || isSingle ? null : [],
  );
  const [startTimer, setStartTimer] = useState(false);
  const [waitingForPlayer, setWaitingForPlayer] = useState(true);

  useEffect(() => {
    onAnswered(practiceAnswer);
  }, [practiceAnswer]);

  useEffect(() => {
    if (mockAnswer && answer && answer.length > 0) {
      setPracticeAnswer(answer);
    }
  }, [answer]);

  const handleMultiSelectChange = (event) => {
    if (submittedQuestion) {
      return;
    }
    const { checked, name } = event.target;

    if (checked) {
      if (practiceAnswer?.indexOf(name < 0)) {
        setPracticeAnswer((prevAnswer) => [...prevAnswer, name]);
      } else {
        setPracticeAnswer([name]);
      }
    } else if (practiceAnswer?.indexOf(name >= 0)) {
      const prevAnswer = [...practiceAnswer];
      prevAnswer.splice(prevAnswer.indexOf(name), 1);
      setPracticeAnswer(prevAnswer);
    }
  };

  const handleSingleSelectChange = (e) => {
    if (submittedQuestion) return;
    setPracticeAnswer(e.target.value);
  };

  const renderMulti = () => {
    return (
      <Grid container direction="column" spacing={1}>
        {question?.question_data?.options.map((item, index) => (
          <Grid item xs={12} key={index} alignItems="center" className={classes.optionContainer} >
            <FormControlLabel
              className={clsx({
                [classes.wrongAnswer]:
                  submittedQuestion &&
                  questionAnswer &&
                  questionAnswer['acceptable-answers'] &&
                  questionAnswer['acceptable-answers'].data &&
                  questionAnswer['acceptable-answers'].data[0]?.answer?.indexOf(
                    item,
                  ) < 0 &&
                  practiceAnswer?.indexOf(item) >= 0,
                [classes.rightAnswer]:
                  submittedQuestion &&
                  questionAnswer &&
                  questionAnswer['acceptable-answers'] &&
                  questionAnswer['acceptable-answers'].data &&
                  questionAnswer['acceptable-answers'].data[0]?.answer?.indexOf(
                    item,
                  ) >= 0,
              })}
              control={
                <Checkbox
                  disabled={submittedQuestion}
                  checked={
                    mockAnswer &&
                    practiceAnswer &&
                    practiceAnswer?.indexOf(item) >= 0
                  }
                  onChange={handleMultiSelectChange}
                  name={item}
                  color="primary"
                  classes={{
                    disabled:
                      practiceAnswer?.indexOf(item) >= 0
                        ? classes.disabledOption
                        : '',
                  }}
                />
              }
              label={item}
              classes={{ disabled: classes.disabledLabel, }}
            />
            <span>
              {submittedQuestion &&
                questionAnswer &&
                questionAnswer['acceptable-answers'] &&
                questionAnswer['acceptable-answers'].data &&
                questionAnswer['acceptable-answers'].data[0]?.answer?.indexOf(
                  item,
                ) >= 0 ? (
                <CheckIcon
                  color={theme.palette.primary.main}
                  className={classes.icon}
                />
              ) : null}
              {submittedQuestion &&
                questionAnswer &&
                questionAnswer['acceptable-answers'] &&
                questionAnswer['acceptable-answers'].data &&
                questionAnswer['acceptable-answers'].data[0]?.answer?.indexOf(
                  item,
                ) < 0 &&
                practiceAnswer?.indexOf(item) >= 0 ? (
                <CrossIcon color="#f00" className={classes.icon} />
              ) : null}
            </span>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderSingle = () => {
    return (
      <Grid container direction="column" spacing={1}>
        {question?.question_data?.options.map((item, index) => (
          <Grid item key={index} className={classes.optionContainer} >
            <FormControlLabel
              value={item}
              className={clsx({
                [classes.wrongAnswer]:
                  submittedQuestion &&
                  questionAnswer &&
                  questionAnswer['acceptable-answers']?.data &&
                  practiceAnswer === question.question_data.options[index] &&
                  questionAnswer['acceptable-answers']?.data[0]?.answer !==
                  practiceAnswer,
                [classes.rightAnswer]:
                  submittedQuestion &&
                  questionAnswer &&
                  questionAnswer['acceptable-answers']?.data &&
                  questionAnswer['acceptable-answers']?.data[0]?.answer ===
                  question.question_data.options[index],
              })}
              control={
                <Radio
                  value={item}
                  onChange={handleSingleSelectChange}
                  checked={item === practiceAnswer}
                  name={item}
                  color="primary"
                  checkedIcon={<RadioCheckedIcon />}
                  icon={<RadioUnCheckedIcon />}
                />
              }
              label={item}
            />
            <span>
              {submittedQuestion &&
                questionAnswer &&
                questionAnswer['acceptable-answers']?.data &&
                (questionAnswer['acceptable-answers']?.data[0]?.answer ===
                  question.question_data.options[index] ? (
                  <CheckIcon
                    color={theme.palette.primary.main}
                    className={classes.icon}
                  />
                ) : (
                  practiceAnswer === question.question_data.options[index] && (
                    <CrossIcon color="#f00" className={classes.icon} />
                  )
                ))}
            </span>
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        {isListening ? (
          <Grid item lg={5} md={6} sm={12} xs={12} className={!mockAnswer ? classes.playerContainer : null}>
            <Player
              currentStatus={true}
              setIsStopNextQuestion={setIsStopNextQuestion}
              isAnswer={false}
              t={t}
              src={question.question_media ? Object.values(question.question_media.audio)[0] : null}
              autoplayAfter={playerStartAfter}
              onPlayStart={() => { setWaitingForPlayer(false); }}
              onPlayFinish={() => { setStartTimer(true); }}
              pausePlay={pausePlay}
              useragent={useragent}
              mock={mock}
              showStartTimer={showStartTimer}
              justifyContentPlayer={resultComponent ? 'start' : null}
              onPlayStarted={onPlayerStarted}
            />
          </Grid>
        ) : (
          <Typography variant="body1" className={classes.questionBody} style={{marginTop : '10px'}}>
            {question?.question_data?.text}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} className={classes.choiceContainer}>
        <Typography variant="body1" className={classes.questionText}>
          {isListening && isHighlight ? (
            <>{question?.question_data?.text}</>
          ) : (
            <>
              {question?.question_data?.question
                ? question.question_data.question
                : null}
            </>
          )}
        </Typography>
        {!isSingle ? renderMulti() : renderSingle()}
      </Grid>
    </Grid>
  );
};

ReadingChoicePractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_media: PropTypes.array.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isSingle: PropTypes.bool.isRequired,
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
  isListening: PropTypes.bool,
  useragent: PropTypes.object,
  pausePlay: PropTypes.bool,
  mock: PropTypes.bool,
  isHighlight: PropTypes.bool,
};
ReadingChoicePractice.defaultProps = {
  answer: null,
  questionAnswer: null,
  isListening: false,
  useragent: {},
  pausePlay: false,
  mock: false,
  isHighlight: false,
};

export default ReadingChoicePractice;
